import { styled as muiStyled } from '@mui/material/styles';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';

export const CustomizedToolTip: any = muiStyled(
  ({ className, ...props }: TooltipProps & { invertColor?: boolean }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(({ theme, invertColor }) => ({
  background: 'unset',
  backgroundColor: 'unset',
  padding: 'unset',
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: invertColor
      ? (theme as any).vars.palette.background.default
      : (theme as any).vars.palette.background.paper,
    color: (theme as any).vars.palette.text.primary,
    borderRadius: '8px',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    fontFamily: 'Montserrat',
    dropShadow: `1px 1px 2px ${(theme as any).vars.palette.background.paper}`,
  },
}));
